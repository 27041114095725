import React from "react"
import Layout from "../components/layout"

export default function Contact() {
    return (
        <Layout>
            <h5>Jika ada hal yang ingin ditanyakan terkait dengan Web Blogku hubungi saya! 
                di email ini ya.</h5>
            <p>
                <a href="mailto:syukrie@aretacollege.com">syukrie[at]aretacollege.com</a>
            </p>
        </Layout>
    )
}